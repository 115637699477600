<div class="container-fluid my-3">
  <div class="row">
    <div class="col-12 col-lg-9">
      <div class="container mt-3">
        <mat-toolbar color="primary">
          <a
            *ngIf="user?.admin"
            color="warn"
            mat-flat-button
            (click)="exportExcel()"
            class="px-1"
          >
            Export
          </a>
          <div class="spacer"></div>
          <a
            mat-flat-button
            color="accent"
            (click)="displayForm()"
            class="px-1"
          >
            Add Another Flat
          </a>
        </mat-toolbar>

        <!-- Filter Form -->
        <div class="my-3" *ngIf="user?.admin">
          <h4>Filter by Flat Number</h4>

          <div class="row">
            <div class="col-md-8">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Flat Number</mat-label>
                <input
                  matInput
                  placeholder="Enter flat number"
                  [(ngModel)]="selectedFlatNumber"
                  name="flatNumber"
                />
              </mat-form-field>
            </div>
            <div class="col-md-4 d-flex align-items-center">
              <button
                class="m-2 p-2"
                (click)="searchBtnClick()"
                mat-raised-button
                color="primary"
              >
                Search
              </button>
            </div>
          </div>
        </div>

        <div
          class="row g-2 m-2 justify-content-center"
          *ngIf="user?.admin && statusCounts"
        >
          <div
            class="col-12 col-md-4 col-lg-3"
            *ngFor="let status of statusCounts | keyvalue"
          >
            <mat-card>
              <mat-card-header>
                <mat-card-title>{{ status.key | titlecase }}</mat-card-title>
              </mat-card-header>
              <mat-card-content>
                <p>Count: {{ status.value }}</p>
              </mat-card-content>
            </mat-card>
          </div>
        </div>

        <tt-claims-table
          [data]="filteredClaims"
          (viewClaimDetails)="handleViewClaimDetails($event)"
          (statusUpadateClick)="handleStatusUpdate($event)"
        ></tt-claims-table>
      </div>

      <div *ngIf="selectedClaim">
        <tt-owner-request-form-view
          [selectedClaim]="selectedClaim"
          (onClose)="closedSelectedClaimView($event)"
          (statusUpdateClicked)="handleStatusUpdate($event)"
        ></tt-owner-request-form-view>
      </div>

      <div class="container mt-3" *ngIf="isEligibleToDisplay()">
        <mat-toolbar color="primary" class="form-header">
          <h1 *ngIf="!formSubmitted; else confirmationTitle">
            Owners Data Request Form
          </h1>
        </mat-toolbar>

        <ng-template #confirmationTitle>
          <h1>Thank You for Submitting Your Data</h1>
        </ng-template>

        <div *ngIf="isSubmitting" class="overlay">
          <mat-spinner></mat-spinner>
        </div>

        <mat-card
          class="form-card"
          *ngIf="!formSubmitted; else confirmationMessage"
        >
          <form [formGroup]="ownerForm" (ngSubmit)="onSubmit()">
            <!-- Owner Information -->
            <h3><strong>Owner Details</strong></h3>
            <!-- Grid container for responsive layout -->
            <div class="form-grid">
              <div class="form-col">
                <mat-form-field appearance="fill">
                  <mat-label>Owner Name</mat-label>
                  <input matInput formControlName="ownerName" required />
                  <mat-error
                    *ngIf="ownerForm.get('ownerName')?.hasError('required')"
                  >
                    Owner Name is required
                  </mat-error>
                  <mat-error
                    *ngIf="ownerForm.get('ownerName')?.hasError('minlength')"
                  >
                    Owner Name should be at least 3 characters
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="form-col">
                <mat-form-field appearance="fill">
                  <mat-label>Owner Flat Number</mat-label>
                  <input
                    matInput
                    formControlName="flatNumber"
                    placeholder="e.g., L901 or K801"
                    required
                  />
                  <mat-error
                    *ngIf="ownerForm.get('flatNumber')?.hasError('required')"
                  >
                    Flat Number is required
                  </mat-error>
                  <mat-error
                    *ngIf="ownerForm.get('flatNumber')?.hasError('pattern')"
                  >
                    Flat Number must be 1 uppercase alphabet followed by 3
                    digits.
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="form-col">
                <mat-form-field appearance="fill">
                  <mat-label>Mobile Number</mat-label>
                  <input
                    matInput
                    formControlName="mobileNumber"
                    required
                    type="tel"
                  />
                  <mat-error
                    *ngIf="ownerForm.get('mobileNumber')?.hasError('required')"
                  >
                    Mobile Number is required
                  </mat-error>
                  <mat-error
                    *ngIf="ownerForm.get('mobileNumber')?.hasError('pattern')"
                  >
                    Mobile Number must be 10 digits
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="form-col">
                <mat-form-field appearance="fill">
                  <mat-label>Email Address</mat-label>
                  <input
                    matInput
                    formControlName="email"
                    [value]="user?.email"
                    [readonly]="!user?.admin"
                    [disabled]="!user?.admin"
                  />
                  <mat-error
                    *ngIf="ownerForm.get('email')?.hasError('required')"
                  >
                    Email is required
                  </mat-error>
                  <mat-error *ngIf="ownerForm.get('email')?.hasError('email')">
                    Invalid email address
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="form-col">
                <mat-form-field appearance="fill">
                  <mat-label>Purchase Type</mat-label>
                  <mat-select formControlName="purchaseType" required>
                    <mat-option value="direct">Direct with Builder</mat-option>
                    <mat-option value="resale">Resale</mat-option>
                  </mat-select>
                  <mat-error
                    *ngIf="ownerForm.get('purchaseType')?.hasError('required')"
                  >
                    Purchase type is required
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="form-col">
                <mat-form-field appearance="fill">
                  <mat-label>Aadhar Number</mat-label>
                  <input matInput formControlName="aadharNumber" required />
                  <mat-error
                    *ngIf="ownerForm.get('aadharNumber')?.hasError('required')"
                  >
                    Aadhar Number is required
                  </mat-error>
                  <mat-error
                    *ngIf="ownerForm.get('aadharNumber')?.hasError('pattern')"
                  >
                    Aadhar Number must be 12 digits
                  </mat-error>
                </mat-form-field>
              </div>

              <!-- Aadhar Card Upload -->
              <div class="form-col">
                <div class="file-upload mat-form-field">
                  <mat-label>Aadhar Card Upload</mat-label>
                  <input
                    type="file"
                    (change)="onFileChange($event)"
                    accept=".pdf,image/*"
                    id="fileInput"
                  />
                  <label for="fileInput" class="custom-file-label"
                    >Choose file...</label
                  >
                  <mat-error *ngIf="aadharFileError">{{
                    aadharFileError
                  }}</mat-error>
                </div>
                <!-- Display the selected file name and delete button -->
                <div *ngIf="aadharFile" class="file-name">
                  {{ aadharFile.name }}
                  <button
                    type="button"
                    mat-icon-button
                    color="warn"
                    (click)="clearFile()"
                  >
                    <mat-icon>delete</mat-icon>
                  </button>
                </div>
              </div>
            </div>

            <!-- Bank Account Details -->
            <h3><strong>Bank Account Details</strong></h3>
            <div class="form-grid">
              <div class="form-col">
                <mat-form-field appearance="fill">
                  <mat-label>Account Number</mat-label>
                  <input matInput formControlName="accountNumber" required />
                  <mat-error
                    *ngIf="ownerForm.get('accountNumber')?.hasError('required')"
                  >
                    Account Number is required
                  </mat-error>
                  <mat-error
                    *ngIf="
                      ownerForm.get('accountNumber')?.hasError('minlength')
                    "
                  >
                    Account Number should be at least 6 digits
                  </mat-error>
                  <mat-error
                    *ngIf="
                      ownerForm.get('accountNumber')?.hasError('maxlength')
                    "
                  >
                    Account Number cannot exceed 20 digits
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="form-col">
                <mat-form-field appearance="fill">
                  <mat-label>Account Holder Name</mat-label>
                  <input
                    matInput
                    formControlName="accountHolderName"
                    required
                  />
                  <mat-error
                    *ngIf="
                      ownerForm.get('accountHolderName')?.hasError('required')
                    "
                  >
                    Account Holder Name is required
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="form-col">
                <mat-form-field appearance="fill">
                  <mat-label>Bank Name</mat-label>
                  <input matInput formControlName="bankName" required />
                  <mat-error
                    *ngIf="ownerForm.get('bankName')?.hasError('required')"
                  >
                    Bank Name is required
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="form-col">
                <mat-form-field appearance="fill">
                  <mat-label>IFSC Code</mat-label>
                  <input matInput formControlName="ifscCode" required />
                  <mat-error
                    *ngIf="ownerForm.get('ifscCode')?.hasError('required')"
                  >
                    IFSC Code is required
                  </mat-error>
                  <mat-error
                    *ngIf="ownerForm.get('ifscCode')?.hasError('pattern')"
                  >
                    Invalid IFSC Code
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="form-col">
                <mat-form-field appearance="fill">
                  <mat-label>Branch</mat-label>
                  <input matInput formControlName="branch" required />
                  <mat-error
                    *ngIf="ownerForm.get('branch')?.hasError('required')"
                  >
                    Branch is required
                  </mat-error>
                </mat-form-field>
              </div>
            </div>

            <div *ngIf="errorMessage">
              <div class="container text-center mt-5">
                <div class="alert alert-danger fade show" role="alert">
                  <strong>Error:</strong>
                  <p>${{ errorMessage }}</p>
                  >
                </div>
              </div>
            </div>

            <!-- Owner Consent (Updated) -->
            <mat-checkbox formControlName="consent">
              I agree to share my personal and property information with the
              association committee members, and legal professionals (such as
              lawyers) for managing association matters and legal processes.
            </mat-checkbox>
            <mat-error
              *ngIf="ownerForm.get('consent')?.hasError('requiredTrue')"
            >
              You must agree to proceed
            </mat-error>

            <!-- Special Notes and Disclaimer -->
            <mat-card class="disclaimer">
              <h4>Disclaimer</h4>
              <div>
                <p>
                  The form, including the claim amount, interest calculations,
                  and your personal information (such as your Aadhar number),
                  will be pre-populated for your review and e-signature. Owners
                  are required to e-sign and submit the form through the
                  platform to proceed with the claim.
                  <span style="color: red">
                    Please note, without the signed form, the claim will not be
                    eligible for submission at NCLT/RP.</span
                  >
                </p>
              </div>
              <div>
                <p>
                  Additionally, the form will include an authorization letter
                  granting the association the power to represent you as an
                  individual home buyer in the NCLT court. This authorization is
                  required for the association to advocate on your behalf during
                  legal proceedings.
                </p>
              </div>
              <div>
                <p></p>
                <p><strong>Terms & Conditions</strong></p>
                <p>
                  We do not store or retain e-signature images. However, we
                  retain other essential contact information, including the
                  owner's personal details, email, and mobile number, bank
                  account information. These details will be stored solely for
                  future use within the association and for any necessary
                  communication related to the claim process. Neither the
                  platform provider nor the association will have access to any
                  signature or image data once submitted. All personal
                  information is used exclusively for processing the claim and
                  will not be stored for any further use beyond the
                  association's requirements.
                </p>
              </div>
            </mat-card>

            <!-- Submit Button -->
            <button
              mat-raised-button
              color="primary"
              type="submit"
              [disabled]="!ownerForm.valid"
            >
              Submit
            </button>
          </form>
        </mat-card>

        <ng-template #confirmationMessage>
          <div class="container">
            <mat-card>
              <div class="m-2 p-2">
                <h2>Owners Data Submitted for Pre Population</h2>
                <p>
                  The committee will review your details and send an email to
                  <strong>{{ user?.email }}</strong> with the Partially
                  prepopulated form CA and an authorization letter for your
                  signature.
                </p>
                <p>
                  Please print, sign, scan, and
                  <strong style="color: red">upload the signed document</strong>
                  to the as a soft copy . Also, mail the hard copy to the
                  association’s address to submit it to the court on the day of
                  the hearing.
                </p>

                <p>
                  Owners are required to sign and send it back for resubmission.
                  <span class="text-danger">
                    Without the signed copy, the claim will not be eligible to
                    submit at NCLT/RP.
                  </span>
                </p>
                <p *ngIf="errorMessage" class="error">{{ errorMessage }}</p>
                <p *ngIf="successMessage" class="success">
                  {{ successMessage }}
                </p>
                <!-- Back to Home Button -->
                <a
                  mat-raised-button
                  color="primary"
                  (click)="onFormReset()"
                  class="mt-3 w-50"
                >
                  Submit Another Response
                </a>
              </div>
            </mat-card>
          </div>
        </ng-template>
      </div>
    </div>
    <div class="col-12 col-lg-3">
      <tt-profile></tt-profile>
      <div *ngIf="user?.admin" class="">
        <app-admin-dashboard></app-admin-dashboard>
      </div>
    </div>
  </div>
</div>
