export const environment = {
  production: false,
  path: "/ncltclaims/llbfowa",
  clientId: "llbfowa",
  baseUrl: "https://nclt.llbfowa.com/api",
  eSignEnabled: true,
  firebaseConfig: {
    apiKey: "AIzaSyCr3ep1F_ljCnu2DsCx1_WnffIFwrNS4j4",
    authDomain: "llbfowa.firebaseapp.com",
    projectId: "llbfowa",
    storageBucket: "llbfowa.appspot.com",
    messagingSenderId: "141904170695",
    appId: "1:141904170695:web:e25e6f8a1b033d5107f8e2",
    measurementId: "G-YY3JTXLZFZ",
  },
};
